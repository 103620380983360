@import '../../../styles/media.scss';

.form {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto!important;
}

.fields {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 20px;
    margin-bottom: 20px;
}

.group {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.button {
    max-width: 260px;
    width: 100%;
}

.privacy {
    max-width: 260px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: rgba(6, 7, 27, 0.5);
    margin-top: 11px;
}
