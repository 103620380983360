$mediaM: 640px;
$mediaL: 1240px;

@mixin mediaM {
  @media (min-width: $mediaM) {
    @content;
  }
}

@mixin mediaL {
  @media (min-width: $mediaL) {
    @content;
  }
}
