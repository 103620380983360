.container {
    font-size: 12px;
}

.title {
    color: var(--color-title-invert);
    font-size: 18px;
    font-weight: 500;
    line-height: 1.21;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.entry {
    white-space: pre-line;
}

.accordion {
    margin-top: 16px;
}

.buttonsContainer {
    margin-top: 35px;
    display: grid;
    gap: 1px;
    justify-content: space-between;
    grid-template-columns: repeat(2, auto);
}

.accordionTitle {
    color: var(--color-title-invert);
}
