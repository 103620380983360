@import '../../../styles/media.scss';

.item {
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 8px 24px;
    transition: .2s;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: inherit;

    @media (max-width: 339px) {
        padding-left: 18px;
        padding-right: 18px;
    }
    @include mediaL {
        padding-left: 26px;
        padding-right: 26px;
        font-size: 16px;
        line-height: 19px;
    }

    &:hover {
        background: rgba(255, 255, 255, 0.2);
        color: inherit;

        .icon {
            opacity: 0.5;
        }
    }
}

.lightTheme:hover {
    background: rgba(0, 0, 0, 0.2);
}

.small {
    padding: 4px 10px;
    text-transform: unset;
}

.active {
    &,&:hover {
        background: var(--color-action);
        color: var(--color-white);

        .icon {
            opacity: 1;
        }
    }
}

.icon {
    margin-right: 8px;
    filter: invert(1);
    opacity: 0.5;
    transition: .2s;
}
