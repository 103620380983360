@mixin setColor($background) {
    &::before, &::after {
        background: $background;
    }
}

@mixin setSize($size, $width: null) {
    width: $size;
    height: $size;

    @if $width != null {
        &::before, &::after {
            height: $width;
        }
    }
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-shrink: 0;

    @include setSize(24px, 2px);
    @include setColor(#fff);

    &::before,
    &::after {
        content: '';
        position: absolute;
        border-radius: 2px;
        width: 100%;
        transition: .2s;
        transform: perspective(0);
    }
    &::after {
        transform: rotate(90deg);
    }
}

.grayIcon {
    @include setColor(#83838d);
}
.blackIcon {
    @include setColor(#000);
}
.blueIcon {
    @include setColor(var(--color-blue));
}

.smallIcon {
    @include setSize(15px, 1px);
}
.largeIcon {
    @include setSize(30px, 3px);
}

.closeIcon {
    &::before {
        transform: rotate(-45deg);
    }
    &::after {
        transform: rotate(45deg);
    }
}

.minusIcon {
    &::after {
        transform: rotate(90deg) scale(0, 1);
    }
}


