.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 80px;
  opacity: 0.5;
  margin-bottom: 20px;
}
