@import '../../../styles/media.scss';

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
}

.group {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.button {
    margin-top: 25px;
    max-width: 260px;
    width: 100%;
}

.forgot {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25;
    margin-top: 10px;
}
