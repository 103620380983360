@import '../../styles/media.scss';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #bababa;
    border-radius: 0;
    outline: none;
    background-color: transparent;
    padding: 6px 8px 8px;
    height: 35px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    color: inherit;

    @include mediaL {
        font-size: 16px;
        line-height: 1.5;
    }

    &::placeholder {
        color: rgba(90, 90, 90, 0.5);
    }

    &[aria-invalid='true'] {
        border-color: var(--color-red);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: #06071B;
        -webkit-box-shadow: 0 0 0 40rem #f0f3f7 inset;
    }
}

.error {
    font-size: 12px;
    color: rgba(6, 7, 27, 0.5);
    margin-top: 5px;
}

.error {
    color: var(--color-red);
}
