.container {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.inactive {
    color: rgba(0, 0, 0, 0.2);

    &:before {
        background-color: rgba(0, 0, 0, 0.2);
    }
}
