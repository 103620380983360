@import '../../styles/media.scss';

.container {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include mediaM {
        max-width: $mediaM;
        padding-left: 20px;
        padding-right: 20px;
    }
    @include mediaL {
        max-width: $mediaL;
    }
}
