.selectContainer {
  font-size: 14px;
  line-height: normal;

  :global {
    .select__control {
      background-color: var(--color-bg);
      color: var(--color-title);
      border: none;
      cursor: pointer;
      min-height: 24px;
      padding: 2px;
      box-shadow: none;
      flex-wrap: nowrap;

      &:hover {
        border-color: #888;
      }
    }

    .select__value-container {
      padding: 0;
      margin-right: 4px;
      height: 16px;
      min-width: 21px;
    }

    .select__single-value {
      color: inherit;
      text-align: center;
      margin: 0;
    }

    .select__menu {
      background-color: var(--color-bg);
      color: var(--color-title);
      overflow: hidden;
      margin-top: 5px;
      box-shadow: 0 0 0 1px hsla(0, 0%, 100%, 0.1), 0 4px 11px hsla(0, 0%, 100%, 0.1);
    }

    .select__menu-list {
      padding: 0;
      width: auto;
    }

    .select__option {
      cursor: pointer;
      padding: 4px;
      text-align: center;
      transition: .1s;
    }

    .select__option--is-selected {
      background-color: #434343;
    }

    .select__option--is-focused {
      background-color: #3a3a3a;
    }

    .select__placeholder {
      color: #a9a9a9;
    }
  }
}

.dropdownIndicator {
  display: flex;
  align-items: center;
  height: 100%;
  color: var(--color-green);
  transition: .2s;
}

.dropdownIndicatorFocused {
  color: var(--color-action);
}

.dropdownIndicatorDisabled {
  color: var(--color-regular);
}
