@import '../../styles/media.scss';

.container {
    padding-top: 57px;
    padding-bottom: 60px;

    @include mediaM {
        padding-top: 49px;
    }
    @include mediaL {
        padding-top: 65px;
        font-size: 20px;
        padding-bottom: 135px;
    }
}

.block {
    margin-top: 80px;

    @include mediaL {
        margin-top: 160px;
    }
}

.advantageBlock {
    margin-top: 14px;

    @include mediaM {
        margin-top: 105px;
    }
}

.container p {
    margin-bottom: 20px;

    @include mediaL {
        margin-bottom: 15px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}
