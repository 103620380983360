@import '../../styles/media.scss';

.input {
    border: 1px solid var(--color-regular);
    background-color: transparent;
    border-radius: 4px;
    height: 35px;
    padding: 7px 11px;

    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    color: inherit;

    @include mediaL {
        font-size: 16px;
        line-height: 19px;
    }

    &[disabled] {
        background-color: rgba(255, 255, 255, 0.06);
        cursor: not-allowed;
        pointer-events: none;
    }

    &[aria-invalid='true'] {
        border-color: var(--color-red);
    }
}

.black {
    border-color: var(--color-bg);
    color: var(--color-bg);
}

.gray {
    border-color: rgba(0, 0, 0, 0.5);
    color: rgba(0, 0, 0, 0.5);
}
