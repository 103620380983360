@import '../../../../styles/media.scss';

.input {
    margin-top: 15px;
    width: 100%;
}

.buttonBlock {
    padding-top: 40px;
    text-align: center;

    @include mediaL {
        padding-top: 26px;
    }
}
.button {
    max-width: 240px;
    width: 100%;
    align-self: center;
}

.error {
    font-size: 12px;
    color: var(--color-red);
    margin-top: 5px;
}
