@import '../../../../styles/media.scss';

.inlineLogo {
    max-width: 100%;
    width: 400px;
    vertical-align: text-top;
    filter: invert(67%) sepia(46%) saturate(533%) hue-rotate(97deg) brightness(105%) contrast(107%);
    margin-top: 0.08em;
    margin-bottom: 0.46em;

    @include mediaM {
        width: 208px;
        margin-top: -0.05em;
        margin-bottom: 0.36em;
    }
    @include mediaL {
        width: 372px;
        margin-top: 0;
        margin-bottom: 0;
        box-sizing: content-box;
        padding: 0 0.1em;
    }
}

.title {
    font-weight: 500;
    font-size: 28px;
    line-height: 1.21;
    text-transform: uppercase;
    margin-bottom: 20px;

    @include mediaL {
        font-size: 50px;
        margin-bottom: 25px;
    }
}


.text {
    max-width: 328px;

    @include mediaL {
        max-width: 475px;
    }
}

.buttonBlock {
    margin-top: 40px;

    @include mediaM {
        margin-top: 40px;
    }
    @include mediaL {
        margin-top: 60px;
    }
}
