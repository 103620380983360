.pagination {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    gap: 12px;

    :global {
        .rc-pagination-item a {
            transition: .2s;
            background-color: #06071b;
            display: flex;
            justify-content: center;
            align-items: center;
            font-style: normal;
            border: 1px solid #e2eaf2;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #fff;
            padding: 0;
            width: 35px;
            height: 35px;
            cursor: pointer;

            &:hover {
                background-color: #f0f3f7;
                border-color: #dae6ee;
                color: #06071b;
            }
        }

        .rc-pagination-item-active a {
            background-color: #2957fa;
            border-color: #2957fa;
            color: #fff;
        }

        .rc-pagination-jump-next,
        .rc-pagination-jump-prev {
            button {
                transition: .2s;
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: end;
                width: 35px;
                height: 35px;
                border: none;

                &:after {
                    content: '•••' !important;
                    display: block;
                    color: white;
                }
            }
        }
    }
}

.rcPaginationItemLink {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    width: 35px;
    height: 35px;
    cursor: pointer;
    line-height: 0;

    &[disabled] {
        cursor: not-allowed;
        background-color: #7f7f7f;
    }

    svg {
        transition: .2s;
    }

    &:hover svg {
        filter: invert(1);
    }
}

.prev {
    transform: rotate(180deg);
}
