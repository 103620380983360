$padding: 1px;
$toggle-width: 20px;

$container-width: $padding * 2 + $toggle-width * 2;
$container-height: $padding * 2 + $toggle-width;
$container-border-radius: $container-height / 2;

.container {
    display: flex;
    width: $container-width;
    height: $container-height;
    border-radius: $container-border-radius;
    background-color: rgba(188, 195, 208, 0.5);
    transition: 0.2s;
    cursor: pointer;
}

.enabled {
    background-color: var(--color-blue);
}

.locked {
    cursor: not-allowed;
}

.toggle {
    width: $toggle-width;
    height: $toggle-width;
    border-radius: 50%;
    background-color: var(--color-white);
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate($padding, $padding);

    .enabled & {
        transform: translate($padding + $toggle-width, $padding);
    }

    .locked & {
        background-image: url(./lock.svg);
        background-repeat: no-repeat;
        background-position: center;
    }
}
