@import '../../../../../../styles/media.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 13px;

    background: var(--color-title);
    color: var(--color-bg);

    @include mediaM {
        padding-left: 17px;
        padding-right: 17px;
    }
    @include mediaL {
        padding: 40px 20px;
    }
}

.title {
    color: var(--color-action);
    text-align: center;
    margin-bottom: 20px;

    @include mediaL {
        margin-bottom: 25px;
    }
}

.price {
    font-size: 16px;
    line-height: 1.21;
    text-align: center;
}

.priceDefault {
    display: inline;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
}

.priceExtra {
    display: inline;
    color: var(--color-gray);
}

.content {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid var(--color-action);
    flex-grow: 1;
    width: 100%;
    margin-top: 20px;

    @include mediaL {
        padding: 25px 50px 25px;
        margin-top: 25px;
    }
}
