.container {
    margin-top: 5px;
}

.title {
    counter-increment: privacySubparagraph;

    &::before {
        content: counter(privacyParagraph) '.' counter(privacySubparagraph) '. ';
    }
}
