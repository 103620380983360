@import '../../../../styles/media.scss';

.items {
    display: grid;
    gap: 10px 20px;
    color: #fff;

    @include mediaM {
        row-gap: 16px;
    }
    @include mediaL {
        grid-template-columns: repeat(3, 1fr);
    }
}

.tvSignalsIcon,
.tradingViewIcon {
    filter: invert(1);
}
.binanceIcon {
    filter: saturate(0);
}

.tvSignalsIcon {
    width: 236px;
}
.binanceIcon {
    width: 252px;
}
.tradingViewIcon {
    width: 85px;
}

.buttonString {
    margin-top: 40px;
    text-align: center;

    @include mediaL {
        margin-top: 60px;
    }
}
