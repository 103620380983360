@import '../../styles/media.scss';

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.whiteContainer {
  background-color: #fff;
  color: #06071B;
  padding: 40px 10px;
  margin-left: -10px;
  margin-right: -10px;

  @include mediaM {
    padding-left: 53px;
    padding-right: 65px;
    margin-left: unset;
    margin-right: unset;
  }
  @include mediaL {
    padding-left: 45px;
    padding-right: 40px;
  }
}

.loginVariants {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  gap: 15px;
}
