.container {
  width: 24px;
  height: 24px;
  background: var(--color-blue);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}

.line {
  position: absolute;
  width: calc(100% * 2/3);
  height: 2px;
  background-color: #fff;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  left: 50%;
  top: 50%;
  transition: .2s;
}

.burger .line:nth-child(1) {
  top: 25%;
}
.burger .line:nth-child(2) {
  top: 50%;
}
.burger .line:nth-child(3) {
  top: 75%;
}

.close .line {
  width: 20.54px;
}
.close .line:nth-child(1) {
  transform: translate(-50%, -50%) rotate(45deg);
}
.close .line:nth-child(2) {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.close .line:nth-child(3) {
  opacity: 0;
}
