.container {
  margin-top: 15px;
}

.title {
  counter-increment: privacySubparagraph;

  &::before {
    content: counter(privacyParagraph) '.' counter(privacySubparagraph) '. ';
  }
}
