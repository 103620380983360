.dropdownIndicator {
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 100%;
  color: var(--color-green);
  transition: .2s;
}

.dropdownIndicatorFocused {
  color: var(--color-action);
}

.dropdownIndicatorDisabled {
  color: var(--color-regular);
}
