@import '../../styles/media.scss';

.container {
    margin-bottom: 36px;

    @include mediaL {
        margin-bottom: 50px;
    }
}

.item {
    margin-right: 15px;

    &:last-child {
        margin-right: 0;
    }
}
