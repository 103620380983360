@import '../../../../../styles/media.scss';

.container {
    padding: 19px;
    border: 1px solid rgba(255, 255, 255, .38);

    display: flex;
    flex-direction: column;
    min-height: 184px;

    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;

    @include mediaL {
        height: 355px;
    }
}

.imagesWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding-top: 20px;

    @include mediaL {
        padding-bottom: 20px;
    }
}

.images {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-grow: 1;
    gap: 15px;
}

.image {
    width: 100%;
    max-width: 252px;
    max-height: 60px;
    filter: invert(1);
}
