@import '../../../../styles/media.scss';

.container {
    padding-top: 13px;
    padding-bottom: 13px;
    position: sticky;
    top: 0;
    background-color: var(--color-bg);
    z-index: 99;
    transition: .2s;

    @include mediaL {
        padding-top: 35px;
        padding-bottom: 35px;
    }
}

.floating {
    box-shadow: 0 0 50px rgba(255, 255, 255, .2);

    @include mediaL {
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 30px;
    }
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper {
    display: flex;
    flex-direction: column;
}

.logoImage {
    filter: invert(1);
    width: 150px;

    @include mediaL {
        width: 210px;
    }
}

.headerMenu {
    padding-left: 15px;
}

.profileBlock {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-left: 15px;

    @include mediaL {
        margin-left: unset;
    }
}

.profileItem {
    margin-right: 16px;

    @include mediaL {
        margin-right: 38px;
    }

    &:last-child {
        margin-right: 0;
    }
}

.userItem {
    @include mediaL {
        order: -1;
    }
}
