@import '../../../../styles/media.scss';

.title {
    @include mediaL {
        margin-bottom: 23px;
    }
}

.itemTitle {
    color: var(--color-title);

    @include mediaL {
        font-size: 24px;
        line-height: 1.21;
    }
}
