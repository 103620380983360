@import '../../../../../styles/media.scss';

.container {
    border-top: 1px solid rgba(255, 255, 255, .38);
    padding-top: 30px;
    padding-left: 33px;
    padding-bottom: 5px;
    position: relative;
    color: var(--color-white);

    @include mediaL {
        padding-right: 10px;
    }

    &:before {
        counter-increment: capabilitiesBlock;
        content: counter(capabilitiesBlock, decimal-leading-zero);
        font-family: unset;
        position: absolute;
        top: 5px;
        left: 0;
        font-size: 14px;
        color: var(--color-regular);
    }
}
