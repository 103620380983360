@import '../../styles/media.scss';

.popupWrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    justify-content: flex-start;
    pointer-events: none;
    transition: 0.3s ease-in-out;
    overflow-y: auto;
    opacity: 0;
}

.wrapperOpened {
    opacity: 1;
}

.closeWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.8);
}

.scroller {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    padding-top: 10px;
    width: 100%;
    flex-grow: 1;
    position: relative;
    overflow: hidden;

    @include mediaM {
        padding: 20px;
        justify-content: center;
    }
}

.bottomRightScroller {
    @include mediaM {
        justify-content: flex-end;
        align-items: flex-end;
    }
}

.closeBtn {
    position: fixed;
    cursor: pointer;
    z-index: 2;
    display: flex;
    align-self: end;
    transition: 0.3s;
    opacity: 0.5;

    padding: 3px;
    top: 0;
    right: 10px;
    box-sizing: border-box;

    @include mediaM {
        top: 10px;
        right: 27px;
    }

    &:hover {
        opacity: 1;
    }
}

.popupCard {
    padding: 30px 15px;
    width: 100%;
    transition: 0.3s ease-in-out;
    transform: translateY(30%);
    z-index: 1;

    @include mediaM {
        transform: translateY(30%) scale(0.9);
        padding-left: 30px;
        padding-right: 30px;
        max-width: 1000px;
        border-radius: 8px;
    }

    .wrapperOpened & {
        transform: none;
    }
}

.darkCard {
    background-color: var(--color-bg);
    color: var(--color-regular);
    box-shadow: 0 0 50px rgba(255, 255, 255, 0.2);
}

.lightCard {
    background-color: var(--color-bg-invert);
    color: var(--color-regular-invert);
}

.narrowCard {
    padding: 15px;

    @include mediaM {
        max-width: 335px;
    }
}

.activePointer {
    pointer-events: initial;
}
