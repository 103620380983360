.btn {
    display: inline-flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 12px 30px;
    min-width: min(260px, 100%);

    border-width: 1px;
    border-style: solid;

    font-weight: 500;
    font-size: 16px;
    font-family: inherit;
    line-height: 19px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;

    transition: 0.2s;
}

.small {
    min-width: unset;
    font-size: inherit;
    padding: 7px 23px;
}

.reduced {
    min-width: 200px;
    width: 100%;
    font-size: 14px;
    line-height: 17px;
}

.large {
    min-width: min(280px, 100%);
}

.green {
    color: var(--color-bg);
    background: var(--color-green);
    border-color: var(--color-green);

    &:not([disabled]):hover {
        color: var(--color-title);
        background-color: var(--color-bg);
        border-color: var(--color-title);
    }
}

.greenBordered {
    color: var(--color-bg);
    background: var(--color-green);
    border-color: var(--color-bg);

    &:not([disabled]):hover {
        color: var(--color-title);
        background-color: var(--color-bg);
        border-color: var(--color-bg);
    }
}

.blue, .blueBorderless {
    color: var(--color-title);
    background: var(--color-blue);
    border-color: var(--color-blue);

    &:not([disabled]):hover {
        color: var(--color-title);
        background-color: var(--color-bg);
        border-color: var(--color-title);
    }
}
.blueBorderless:not([disabled]):hover {
    border-color: var(--color-bg);
}

.transparent {
    color: inherit;
    background: transparent;
    border-color: var(--color-bg);

    &:not([disabled]):hover {
        color: var(--color-bg);
        background-color: var(--color-green);
        border-color: var(--color-bg);
    }
}

.linkColor {
    color: var(--color-action);
    background: transparent;
    border-color: transparent;
    text-transform: inherit;

    &:not([disabled]):hover {
        color: var(--color-title);
        background-color: var(--color-bg);
        border-color: var(--color-bg);
    }
}

.greenTransparent {
    color: var(--color-green);
    background: transparent;
    border-color: var(--color-green);

    &:not([disabled]):hover {
        color: var(--color-title);
        background-color: var(--color-bg);
        border-color: var(--color-title);
    }
}

.btn[disabled] {
    color: #757575;
    border-color: #7f7f7f;
    cursor: not-allowed;
}
