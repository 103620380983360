@import '../../styles/media.scss';

.container {
    display: flex;
    flex-wrap: wrap;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;

    & > div {
        width: 450px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.logoColumn {
    text-align: center;
    padding: 40px 10px;

    @include mediaM {
        padding-left: 53px;
        padding-right: 65px;
    }
    @include mediaL {
        padding-left: 45px;
        padding-right: 40px;
    }
}

.logoImg {
    width: 350px;
    max-width: 100%;
    filter: invert(1);
}

.logoTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    margin-top: 20px;
    color: var(--color-white);
}

.formColumn {
    @include mediaM {
        min-height: 500px;
    }
}
