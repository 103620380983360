@import '../../../../styles/media.scss';

.items {
    display: grid;
    gap: 30px 16px;
    counter-reset: capabilitiesBlock;
    grid-template-columns: 100%;

    @include mediaM {
        grid-template-columns: repeat(2, 1fr);
    }
    @include mediaL {
        grid-template-columns: repeat(2, 480px);
        column-gap: calc(50% - 480px);
        row-gap: 45px;
    }
}

.item:nth-child(odd):last-child {
    @include mediaM {
        grid-column: 1/3;
    }
    @include mediaL {
        grid-column: unset;
    }
}
