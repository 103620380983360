@import '../../styles/media.scss';

.container {
    padding: 50px 0;
    counter-reset: privacyParagraph;
}

.title {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.21;
    text-transform: uppercase;

    @include mediaL {
        font-size: 36px;
    }
}

.lastRefresh {
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }
}
