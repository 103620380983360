@import '../../styles/media.scss';

.container {
    position: relative;
    display: flex;
    flex-direction: column;
}

.textContainer {
    max-width: 486px;

    @include mediaL {
        max-width: 820px;
    }

    .fixedBg & {
        max-width: unset;
    }
}

.imgContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: -1;
    margin-top: auto;

    .fixedBg & {
        opacity: .2;
        position: absolute;
        top: 410px;
        width: 100%;

        @include mediaM {
            top: 311px;
        }
        @include mediaL {
            top: 503px;
        }
    }
}


.coverImg {
    border: 10px solid #06071B;
    box-sizing: content-box;
    width: 604px;
    margin-top: -30px;
    margin-left: -52px;
    margin-bottom: 80px;
    border-radius: 10px;
    transform: matrix(0.86, -0.52, 0.86, 0.52, 0, 0);
    box-shadow: 0 48px 48px rgba(6, 6, 6, 0.75);
    z-index: 1;

    @include mediaM {
        margin-top: -20px;
        margin-left: 93px;
    }
    @include mediaL {
        width: 774px;
        margin-top: -215px;
        margin-right: -149px;
        align-self: flex-end;
    }
}

.gridImg {
    width: 1445px;
    position: absolute;
    display: none;
    align-self: center;
    top: -102px;

    @include mediaM {
        display: block;
        top: -102px;
    }
    @include mediaL {
        width: 2113px;
        top: -407px;
        left: -152px;
    }
}
