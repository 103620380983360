.item {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: var(--color-title);
    padding: 11px 12px;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: .2s;

    &:hover {
        text-decoration-color: unset;
    }
}

.isActive {
}
