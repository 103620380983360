@import '../../../styles/media.scss';

$toggleAnimationDuration: 0.3s;

.small {
    border-top: 1px solid var(--color-border);
}
.lightStyle {
    border-color: var(--color-border-invert);
}

.header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-border);
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: pointer;
    justify-content: space-between;
    user-select: none;
    gap: 20px;
    transition: $toggleAnimationDuration;

    @include mediaL {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 20px;
    }

    .lightStyle & {
        border-color: var(--color-border-invert);
    }

    .small & {
        border: none;
        gap: 8px;
        padding-right: 0;
    }

    .small.opened & {
        @include mediaL {
            padding-bottom: 12px;
        }
    }
}
.reverseHeader {
    flex-direction: row-reverse;
}

.title {
    text-transform: uppercase;
    margin-right: auto;
}

.plusIcon {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contentWrapper {
    transition: $toggleAnimationDuration;
    pointer-events: none;
    height: 0;

    .opened & {
        pointer-events: unset;
        height: var(--content-height, auto);
    }
}

.content {
    padding-top: 10px;
    padding-bottom: 10px;
    opacity: 0;
    transition: $toggleAnimationDuration / 2;
    white-space: pre-line;

    @include mediaL {
        padding-top: 20px;
        padding-bottom: 0;
    }

    .opened & {
        opacity: 1;
        transition-delay: $toggleAnimationDuration / 2;
    }

    .small & {
        padding-top: 0;
        padding-bottom: 6px;

        @include mediaL {
            padding-bottom: 15px;
        }
    }
}
