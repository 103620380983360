@import '../../../../styles/media.scss';

.container {
    color: var(--color-title);
}

.items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;
    margin-right: -10px;

    @include mediaM {
        margin-bottom: -16px;
        margin-right: -16px;
    }
    @include mediaL {
        margin-bottom: -26px;
        margin-right: -26px;
    }
}

.item {
    margin-bottom: 10px;
    margin-right: 10px;
    flex-grow: 1;
    width: 100%;

    @include mediaM {
        margin-bottom: 16px;
        margin-right: 16px;
        width: 35%;
    }
    @include mediaL {
        margin-bottom: 26px;
        margin-right: 26px;
        width: 25%;
    }
}

.greenText {
    color: var(--color-green);
}

.buttonString {
    margin-top: 40px;
    text-align: center;

    @include mediaL {
        margin-top: 60px;
    }
}
