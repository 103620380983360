@import '../../../../styles/media.scss';

.profileBlock {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  color: var(--color-dark-blue);
  border-radius: 8px;
  padding: 30px 20px;
  margin-bottom: 10px;
  width: 100%;

  @include mediaM {
    margin-bottom: 15px;
    margin-right: 15px;
    width: 33%;
  }
  @include mediaL {
    margin-bottom: 45px;
    margin-right: 45px;
    width: 25%;
  }
}

.profileBlockContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
