@import '../../styles/media.scss';

.items {
    display: grid;
    gap: 10px;

    @include mediaL {
        gap: 20px;
    }
}

.small {
    gap: 0;
    border-bottom: 1px solid var(--color-border);

    &.light {
        border-color: var(--color-border-invert);
    }
}

