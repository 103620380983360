@import '../../styles/media.scss';

.container {
    padding-bottom: 50px;
}

.section + .section {
    margin-top: 80px;

    @include mediaL {
        margin-top: 50px;
    }
}

.text {
    @include mediaM {
        max-width: 501px;
    }
    @include mediaL {
        max-width: 711px;
        font-size: 20px;
    }
}

.form {
    margin-top: 25px;

    @include mediaL {
        margin-top: 30px;
    }
}

.formStartControlsWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.formStartControls {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.title {
    color: var(--color-title);
    font-weight: 400;
    font-size: 16px;
    line-height: 1.21;
    text-transform: uppercase;
    margin-bottom: 1em;

    @include mediaL {
        font-size: 24px;
    }
}

.keyInput {
    width: 100%;
}

.fieldContainer {
    width: 100%;

    @include mediaM {
        max-width: 400px;
    }
    @include mediaL {
        max-width: 350px;
    }
}

.disabledContainer {
    position: relative;
    cursor: pointer;

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}

.copyIcon {
    position: absolute;
    top: 10px;
    right: 11px;
}

.grayLink {
    text-transform: lowercase;
    text-decoration: underline;
    cursor: pointer;

    @include mediaL {
        text-transform: uppercase;
    }
}

.blue {
    color: var(--color-blue);
}

.group {
    margin-bottom: 50px;
}

.removed {
    opacity: 0.5;
}

.removeIcon {
    margin-left: 25px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s;

    &:hover {
        opacity: 1;
    }
}

.error {
    font-size: 12px;
    color: var(--color-red);
    margin-top: 5px;
}
