@import '../../../../../styles/media.scss';

.container {
    padding: 23px;
    border: 1px solid rgba(255, 255, 255, .38);

    @include mediaL {
        padding-bottom: 47px;
    }
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    width: 36px;
    height: 36px;

    @include mediaL {
        margin-bottom: 80px;
    }
}
