@import '../../styles/media.scss';

.wrapper {
    :global {
        table {
            border-collapse: separate;
            border-spacing: 0;
        }

        th, td {
            padding-left: 5px;
            padding-right: 5px;
        }

        th {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 1.21;
            text-transform: uppercase;
            color: var(--color-white);
            border-bottom: 1px solid rgba(255, 255, 255, .38);
            padding-bottom: 18px;
            text-align: left;
        }

        tbody tr:first-child td {
            padding-top: 18px;
        }
    }
}

.pagination {
    padding: 27px 0 35px;
}

.noDataContainer {
    padding: 80px 0;

    @include mediaL {
        padding: 95px 0;
    }
}

.indexColumn {
    width: 0%;
}

