@import '../../../../styles/media.scss';

.input {
    margin-top: 15px;
    width: 100%;
}

.buttonBlock {
    padding-top: 40px;
    text-align: center;

    @include mediaM {
        padding-top: 0;
    }
    @include mediaL {
        padding-top: 26px;
    }
}

.button {
    min-width: 240px;
    align-self: center;

    @include mediaM {
        width: 260px;
        justify-self: end;
    }
    @include mediaL {
        width: unset;
        justify-self: unset;
    }
}

.blueText {
    color: var(--color-blue);
}

.tariffContent {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;

    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @include mediaM {
        display: grid;
        grid-template-columns: auto auto;
        gap: 22px;
        align-items: center;
    }
    @include mediaL {
        display: flex;
        justify-content: space-between;
        gap: unset;
        align-items: unset;
    }
}

.tariffText {
    @include mediaM {
        grid-column: 1/3;
        width: 61%;
    }
    @include mediaL {
        grid-column: unset;
        width: unset;
    }
}

.daysLeft {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    margin-top: 30px;

    @include mediaM {
        margin-top: 0;
    }
    @include mediaL {
        margin-top: 20px;
    }
}
