@import '../../../../styles/media.scss';

.container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    margin-top: 20px;

    @include mediaM {
        row-gap: 5px;
        grid-template-columns: 260px auto;
        margin-top: 25px;
    }
    @include mediaL {
        grid-template-columns: 400px 460px auto;
        gap: 15px;
    }
}

.title {
    color: var(--color-title);
    font-weight: 400;
    font-size: 14px;
    line-height: 1.21;
    text-transform: uppercase;
    margin-bottom: 0;

    @include mediaL {
        font-size: 18px;
    }
}

.extra {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: self-start;

    @include mediaM {
        justify-content: unset;
        grid-column: 1/2;
    }
    @include mediaL {
        justify-content: flex-end;
        grid-column: unset;
        order: 1;
        padding-top: 3px;
        padding-bottom: 3px;
    }
}

.content {
    grid-column: 1/3;

    @include mediaM {
        grid-column: 2/3;
        grid-row: 1/3;
    }
    @include mediaL {
        grid-column: unset;
        grid-row: unset;
        font-size: 20px;
    }
}
