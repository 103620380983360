$padding-left-right-width: 20px;

.pageFiller {
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: .3s;
    pointer-events: none;

    .isOpen & {
        pointer-events: unset;
    }
}

.pageFillerBg {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: var(--color-bg);
    transition: .3s;
    opacity: 0;
    cursor: pointer;

    .isOpen & {
        opacity: 0.6;
    }
}

.floatingMenu {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    height: 100%;

    display: flex;
    flex-direction: column;

    background-color: var(--color-bg);

    transition: .3s;
    transform: translate(100%, 0);

    width: 100%;
    max-width: 310px;

    @media (min-width: 640px) {
        max-width: 325px;
    }

    .isOpen & {
        transform: translate(0, 0);
    }
}

.header {
    padding: 20px $padding-left-right-width;
    width: 100%;
    display: flex;
    align-items: center;
}

.closeButton {
    margin-left: auto;
}

.languageSelector:not(:first-child) {
    margin-left: 15px;
}

.scrollWrapper {
    flex-grow: 1;
    overflow: auto;
}

.gradientHiderOpen,
.gradientHiderClose {
    position: relative;
    display: flex;
    width: 100%;
    height: 0;
    pointer-events: none;

    &:before {
        content: '';
        width: 100%;
        height: 30px;
        background: linear-gradient(0deg, transparent, var(--color-bg) 100%);
    }
}
.gradientHiderClose {
    transform: rotate(180deg);
}

.scroller {
    padding: 30px $padding-left-right-width 40px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}

.footer {
    padding-top: 25px;
    display: grid;
    justify-items: center;
    margin-top: auto;
    gap: 20px;
    text-align: center;
    text-transform: uppercase;
}

.form {
    margin-top: 30px;
}
