.container {
  display: flex;
  overflow: auto;
}

.item {
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
}
