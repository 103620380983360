@import '../../styles/media.scss';

.container {
    padding-top: 35px;

    @include mediaM {
        padding-top: 30px;
    }
    @include mediaL {
        padding-top: 40px;
    }
}

.title {
    margin-bottom: 15px;

    @include mediaM {
        font-size: 44px;
        margin-bottom: 20px;
    }
}

.redText {
    color: var(--color-red);
}

.text {
    max-width: 471px;
}

.linkContainer {
    margin-top: 30px;
}

.list {
    max-width: 471px;
    margin-top: 0.15em;
    padding-left: 22px;
}

.listItem {
    padding-left: 2px;

    &::marker {
        font-size: 10px;
    }
}
