.container {
  margin-bottom: 30px;
  counter-reset: privacySubparagraph;
  white-space: pre-line;
}

.title {
  counter-increment: privacyParagraph;

  &::before {
    content: counter(privacyParagraph) '. ';
  }
}
