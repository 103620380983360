.container {
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: clip;
    overflow-y: clip;
}

.content {
    margin: 0;
    flex-grow: 1;
}
