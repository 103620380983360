@import '../../../../styles/media.scss';

.title {
  color: var(--color-title);
  margin-bottom: 35px;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.21;
  text-transform: uppercase;
  max-width: 920px;

  @include mediaL {
    margin-bottom: 50px;
    font-size: 36px;
  }
}

.superTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.21;
  text-transform: uppercase;
  color: var(--color-action);
  margin-bottom: 10px;

  @include mediaL {
    margin-bottom: 20px;
  }
}
