@import '../../../../styles/media.scss';

.container {
}

.title {
    margin-bottom: 35px;

    @include mediaL {
        margin-bottom: 50px;
    }
}

.items {
    display: flex;
    flex-wrap: wrap;
    margin-right: -16px;
    margin-bottom: -10px;

    @include mediaL {
        margin-right: -60px;
    }
}

.items > * {
    width: 290px;
    flex-grow: 1;
    margin-right: 16px;
    margin-bottom: 10px;

    @include mediaL {
        margin-right: 60px;
    }
}
