@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    border: 4px solid var(--color-regular);
    padding: 15px;
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
  }
}
