@import '../../styles/media.scss';

.profileBlocks {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;

    @include mediaM {
        margin-bottom: -15px;
        margin-right: -15px;
    }
    @include mediaL {
        margin-bottom: -45px;
        margin-right: -45px;
    }
}

.form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    & > *:last-child {
        margin-top: auto;
    }
}

.highProfileBlock {
    min-height: 370px;
}

.profileBlockTitle {
    padding: 10px 0 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.21;
    text-transform: uppercase;
    text-align: center;
}

.input {
    margin-top: 15px;
    width: 100%;
}

.button {
    margin-top: 40px;
    min-width: 240px;
    align-self: center;

    @include mediaL {
        margin-top: 26px;
    }
}
